<template>
    <div class="main">
        <div class="row row1">

            <el-card class="row1-content" v-for="item in  headerData">
                <div class="content">
                    <div class="text">
                        <p class="title">{{ item.title }}</p>
                        <p class="number">{{ item.numbe }}</p>
                    </div>
                    <img class="image" v-if="item.icon=='@/assets/images/icon2.png'" src="@/assets/images/icon2.png" alt="">
                    <img class="image" v-if="item.icon=='@/assets/images/icon1.png'" src="@/assets/images/icon1.png" alt="">
                    <img class="image" v-if="item.icon=='@/assets/images/icon3.png'" src="@/assets/images/icon3.png" alt="">
                </div>

            </el-card>

        </div>
        <div class="row" v-for="item in rowData">
            <el-card class="row-card">
               <div class="title-header">
                    <p class="header">{{ item.title }} </p>  <span class="time">{{ item.time }}</span>
               </div>
                <div class="heade-content">
                    <div class="item-content" v-for="itemLabel in item.lable">
                        <!-- <img class="image" :src="itemLabel.icon" alt=""> -->
                        <img class="image" v-if="itemLabel.icon=='@/assets/images/icon02.png'" src="@/assets/images/icon02.png" alt="">
                        <img class="image" v-if="itemLabel.icon=='@/assets/images/icon03.png'" src="@/assets/images/icon03.png" alt="">
                        <img class="image" v-if="itemLabel.icon=='@/assets/images/icon04.png'" src="@/assets/images/icon04.png" alt="">
                        <img class="image" v-if="itemLabel.icon=='@/assets/images/icon05.png'" src="@/assets/images/icon05.png" alt="">
                        <img class="image" v-if="itemLabel.icon=='@/assets/images/icon06.png'" src="@/assets/images/icon06.png" alt="">
                      
                        <div class="content">
                            <div class="text">
                                <p class="title">{{ itemLabel.name1 }}{{ itemLabel.value1 }}</p>
                                <p class="number">{{ itemLabel.name2 }}{{ itemLabel.value2 }}</p>
                            </div>

                        </div>
                    </div>
                </div>
            </el-card>

        </div>





    </div>
</template>
<script>
export default {

    data() {
        return {
            rowData: [
                {
                    title: '搜索引擎推广',
                    lable: [
                        {
                            icon: require('@/assets/images/icon02.png'),
                            name1: '总消费:',
                            value1: '39562',
                            name2: '当期消费:',
                            value2: '25600'
                        },
                        {
                            icon: require('@/assets/images/icon03.png'),
                            name1: '总点击量:',
                            value1: '25680',
                            name2: '当期点击量:',
                            value2: '16693'
                        },
                        {
                            icon: require('@/assets/images/icon04.png'),
                            name1: '总转化量:',
                            value1: '39562',
                            name2: '当期转化量:',
                            value2: '14586'
                        },
                        {
                            icon: require('@/assets/images/icon05.png'),
                            name1: '总转化率:',
                            value1: '82.63%',
                            name2: '当期转化率:',
                            value2: '78.92%'
                        },
                    ]

                },
                {
                    title: '推荐引擎推广',
                    lable: [
                        {
                            icon: require('@/assets/images/icon02.png'),
                            name1: '总消费:',
                            value1: '43020',
                            name2: '当期消费:',
                            value2: '19852'
                        },
                        {
                            icon: require('@/assets/images/icon03.png'),
                            name1: '总点击量:',
                            value1: '40063',
                            name2: '当期点击量:',
                            value2: '36205'
                        },
                        {
                            icon: require('@/assets/images/icon04.png'),
                            name1: '总转化量:',
                            value1: '29360',
                            name2: '当期转化量:',
                            value2: '18539'
                        },
                        {
                            icon: require('@/assets/images/icon05.png'),
                            name1: '总转化率:',
                            value1: '36.52%',
                            name2: '当期转化率:',
                            value2: '29.89%'
                        },
                    ]

                },
                {
                    title: '搜索引擎优化',
                    lable: [
                        {
                            icon: require('@/assets/images/icon06.png'),
                            name1: '总搜索引擎优化值:',
                            value1: '5630',
                            name2: '当期搜索引擎优化值:',
                            value2: '2205'
                        },

                    ]

                },
            ],
            headerData: [
                {
                    title: "学生总预算",
                    numbe: 47000,
                    icon: require('@/assets/images/icon2.png')
                },
                {
                    title: "已消费金额",
                    numbe: 46306.6,
                    icon: require('@/assets/images/icon1.png')
                },
                {
                    title: "预算余额",
                    numbe: 693.4,
                    icon: require('@/assets/images/icon3.png')
                },

            ]
        }
    },
    methods:{
        getData(){
            this.$http.axiosGetBy(this.$api.ep_promotionData,{}, (res) => {
               console.log(res)
               if(res.code==200){
                this.rowData=res.data.rowData
                this.headerData=res.data.headerData
               }
            })
            console.log("pppppppppppp");

        }
    },
    mounted(){
        this.getData()
    }
}

</script>
<style scoped lang="scss">
.main {
    width: 100%;
    height: 1000px;
    // background-color: pink;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .row {
        width: 100%;
        height: 240px;

        //    background-color: rgb(39, 120, 191);
        .row-card {
            width: 100%;
            height: 100%;
        }
    }

    .row1 {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .row1-content {
            width: 32%;
            height: 100%;

            .content {
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .text {
                    width: 200px;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .title {

                        height: 44px;

                        font-weight: 400;
                        font-size: 24px;
                        color: #5F636C;
                        line-height: 28px;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }

                    .number {

                        height: 56px;

                        font-weight: 600;
                        font-size: 36px;
                        color: #2A2E34;
                        line-height: 50px;
                        text-align: left;
                        font-style: normal;
                        text-transform: none;
                    }

                }

                .image {
                    width: 180px;
                    height: 180px;
                    display: block;

                }
            }

        }

    }
}

.header {
    width: 144px;
    height: 34px;

    font-weight: 600;
    font-size: 20px;
    color: #333333;
    line-height: 28px;
    text-align: left;
    font-style: normal;
    text-transform: none;
}

::v-deep .el-card__body {
    height: 160px;
}
.heade-content{
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 60px;
    .item-content{
        width: 200px;
        height: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
       
        .image{
            display: block;
            margin-right: 10px;
            width: 56px;
            height: 56px;
        }
        .text{
            width: 200px;
            font-weight: 400;
            font-size: 16px;
            color: #333333;
            line-height: 33px;
            text-align: left;
            font-style: normal;
            text-transform: none;
        }
        
    }
}
.title-header{
    display: flex;
    height: 20px;
    align-items: center;
    .time{
        background-color: rgb(50,165,252);
        color: #fff;
        border-radius: 4px;
        height: 30px;
        display: block;
        line-height: 30px;
        padding: 0 20px;
    }
    
}


</style>